@import '../../global/styles/lib';

// Keep footer at bottom no matter content length
html,
body {
	height: 100%;
	position: relative;
}

#container {
	min-height: 100vh;
	position: relative;
	display: block;
	overflow: hidden;
} // #container

#theme-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: auto;

	clear: both;
	background: #717070 url(smokebg.jpg) center top no-repeat;
	background-size: 100%;
	color: $white;
	font-size: 120%;
	@include gutter(top, padding, 2);
	@include gutter(bottom, padding, 1);

	.container {
		display: block;

		&.large-up {
			display: none;
		} // &.large-up

		.site-logo {
			margin: auto;
			width: 70%;
			max-width: 10rem;
			svg {
				fill: $white;
			} // svg

		} // .site-logo

		.tagline {
			margin-top: 1rem;
			text-align: center;
			font-size: 1.5rem;

		} // .tagline

		.footer-links {
			margin-top: 2rem;
			text-align: center;
		} // .footer-links

		.contact {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 1rem;

			.phone {
				font-weight: 700;
			} // .phone

			.form {
				margin-left: 2rem;
			} // .form

		} // .contact

		.bpf-locations {
			display: flex;
			justify-content: center;
			text-align: center;
			margin: 1rem;
			flex-direction: column;

			& > * {
				width: 100%;
				margin-bottom: 1rem;
			} // & > *

		} // .bpf-locations


		.social-icons {
			text-align: center;

			.icon {
				display: inline-block;
				width: 3rem;
	
				&:not(:last-of-type) {
					margin-right: 1rem;
				}
	
				> a {
					opacity: .7;
					transition: opacity $hover-transition;
	
					&:hover { opacity: 1; }
					> svg {
						color: inherit;
						fill: currentColor;
					}
				} // > a
			} // .icon
		} // .social-icons

		a:not(.button) {
			color: inherit;
		}

		.menu {
			@include inline-list;
		}
		.menu-item {
			text-transform: uppercase;
	
			> a {
				display: inline-block;
				color: inherit;
				padding: 0;
			}
		} // .menu-item

		.copyright {
			text-align: center;
			margin-top: 1rem;
		} // .copyright

	} // .container

	@include breakpoint(medium up) {
		.container {
			.bpf-locations {
				flex-direction: row;
				margin-left: 0;
				& > * {
					width: 45%;
					margin-bottom: 0;
				} // & > *
				
			} // .bpf-locations

		} // .container


		.site-logo {
			max-width: 25rem;
			svg {
				fill: $white;
			} // svg

		} // .site-logo

	} // medium up

	@include breakpoint(large up) {
		.container {
			display: none;

			&.large-up {
				display: flex;
				max-width: $row-max-width;
				margin: auto;
				align-items: flex-end;

				.footer-left,
				.footer-right {
					width: 50%;
				} // .footer-left, .footer-right

				
				.footer-left {
					margin-left: 1rem;

					.logo {
						.site-logo {
							margin: 0;
							width: 11rem;
						} // .site-logo
					} // .logo

					.tagline {
						text-align: left;
					} // .tagline

					.bpf-locations {
						text-align: left;
						justify-content: flex-start;

						& > * {
							width: 45%;
							margin: 0;
						} // & > *
			
					} // .bpf-locations

				} // .footer-left
				.footer-right {
					margin-right: 1rem;

					.social-icons {
						text-align: right;
						margin-top: 0rem;
					} // .social-icons

					.navigation {
						text-align: right;
						margin-top: 1rem;
					} // .navigation

					.contact {
						justify-content: flex-end;
						margin-top: 1rem;
					} // .contact

					.copyright {
						text-align: right;
						margin-top: 1rem;
					} // .copyright
					
				} // .footer-right
				
			} // &.large-up
			
		} // .container

	} // large up
} // #theme-footer