/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
html,
body {
  height: 100%;
  position: relative;
}

#container {
  min-height: 100vh;
  position: relative;
  display: block;
  overflow: hidden;
}

#theme-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  clear: both;
  background: #717070 url(smokebg.jpg) center top no-repeat;
  background-size: 100%;
  color: #fefefe;
  font-size: 120%;
  padding-top: 40px;
  padding-bottom: 20px;
}
@media print, screen and (min-width: 40em) {
  #theme-footer {
    padding-top: 60px;
  }
}
@media print, screen and (min-width: 40em) {
  #theme-footer {
    padding-bottom: 30px;
  }
}
#theme-footer .container {
  display: block;
}
#theme-footer .container.large-up {
  display: none;
}
#theme-footer .container .site-logo {
  margin: auto;
  width: 70%;
  max-width: 10rem;
}
#theme-footer .container .site-logo svg {
  fill: #fefefe;
}
#theme-footer .container .tagline {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
#theme-footer .container .footer-links {
  margin-top: 2rem;
  text-align: center;
}
#theme-footer .container .contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
#theme-footer .container .contact .phone {
  font-weight: 700;
}
#theme-footer .container .contact .form {
  margin-left: 2rem;
}
#theme-footer .container .bpf-locations {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem;
  flex-direction: column;
}
#theme-footer .container .bpf-locations > * {
  width: 100%;
  margin-bottom: 1rem;
}
#theme-footer .container .social-icons {
  text-align: center;
}
#theme-footer .container .social-icons .icon {
  display: inline-block;
  width: 3rem;
}
#theme-footer .container .social-icons .icon:not(:last-of-type) {
  margin-right: 1rem;
}
#theme-footer .container .social-icons .icon > a {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}
#theme-footer .container .social-icons .icon > a:hover {
  opacity: 1;
}
#theme-footer .container .social-icons .icon > a > svg {
  color: inherit;
  fill: currentColor;
}
#theme-footer .container a:not(.button) {
  color: inherit;
}
#theme-footer .container .menu > li {
  display: inline-block;
  list-style: none;
}
#theme-footer .container .menu > li:not(:last-child):after {
  display: inline-block;
  vertical-align: middle;
  content: "|";
  margin: 0 0.6em;
}
#theme-footer .container .menu-item {
  text-transform: uppercase;
}
#theme-footer .container .menu-item > a {
  display: inline-block;
  color: inherit;
  padding: 0;
}
#theme-footer .container .copyright {
  text-align: center;
  margin-top: 1rem;
}
@media screen and (min-width: 40em) {
  #theme-footer .container .bpf-locations {
    flex-direction: row;
    margin-left: 0;
  }
  #theme-footer .container .bpf-locations > * {
    width: 45%;
    margin-bottom: 0;
  }
  #theme-footer .site-logo {
    max-width: 25rem;
  }
  #theme-footer .site-logo svg {
    fill: #fefefe;
  }
}
@media screen and (min-width: 64em) {
  #theme-footer .container {
    display: none;
  }
  #theme-footer .container.large-up {
    display: flex;
    max-width: 75rem;
    margin: auto;
    align-items: flex-end;
  }
  #theme-footer .container.large-up .footer-left,
  #theme-footer .container.large-up .footer-right {
    width: 50%;
  }
  #theme-footer .container.large-up .footer-left {
    margin-left: 1rem;
  }
  #theme-footer .container.large-up .footer-left .logo .site-logo {
    margin: 0;
    width: 11rem;
  }
  #theme-footer .container.large-up .footer-left .tagline {
    text-align: left;
  }
  #theme-footer .container.large-up .footer-left .bpf-locations {
    text-align: left;
    justify-content: flex-start;
  }
  #theme-footer .container.large-up .footer-left .bpf-locations > * {
    width: 45%;
    margin: 0;
  }
  #theme-footer .container.large-up .footer-right {
    margin-right: 1rem;
  }
  #theme-footer .container.large-up .footer-right .social-icons {
    text-align: right;
    margin-top: 0rem;
  }
  #theme-footer .container.large-up .footer-right .navigation {
    text-align: right;
    margin-top: 1rem;
  }
  #theme-footer .container.large-up .footer-right .contact {
    justify-content: flex-end;
    margin-top: 1rem;
  }
  #theme-footer .container.large-up .footer-right .copyright {
    text-align: right;
    margin-top: 1rem;
  }
}